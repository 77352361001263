import React from 'react';

import Layout from '@common/Layout';

import Header from '@sections/Header';
import Links from '@sections/Links';

const IndexPage = () => (
  <Layout>
    <Header />
    <Links />
  </Layout>
);

export default IndexPage;
