import React from 'react';
import styled from 'styled-components';

import ExternalLink from '@common/ExternalLink';

const ButtonLink = ({ title, href }) => (
  <LinkContainer>
    <ExternalLink href={href}>
      <p>{title}</p>
    </ExternalLink>
  </LinkContainer>
);

const LinkContainer = styled.div`
  background: ${(props) => props.theme.color.mainBlue};
  color: ${(props) => props.theme.color.white};
  border: 2px solid ${(props) => props.theme.color.mainBlue};
  margin-bottom: 16px;

  :hover {
    background: ${(props) => props.theme.color.white};
    color: ${(props) => props.theme.color.mainBlue};
  }
  transition: box-shadow 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0s,
    color 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0s,
    border-color 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0s,
    transform 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0s,
    background-color 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0s;
`;

export default ButtonLink;
