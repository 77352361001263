const theme = {
  font: {
    primary: `'Karla', sans-serif`,
    secondary: `'Karla', sans-serif`,
  },
  font_size: {
    small: 'font-size: 20px; line-height: 30px',
    regular: 'font-size: 24px; line-height: 32px',
    large: 'font-size: 30px; line-height: 40px',
    larger: 'font-size: 36px; line-height: 48px',
    xlarge: 'font-size: 48px; line-height: 56px',
  },
  color: {
    darkBlue: '#15246D',
    mainBlue: '#6DB4E4',
    black: '#000',
    white: '#fff',
  },
  screen: {
    xs: '575px',
    sm: '767px',
    md: '991px',
    lg: '1199px',
  },
};

export default theme;
