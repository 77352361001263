import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';

import SEO from '@common/SEO';

import theme from '@styles/theme';
import GlobalStyles from '@styles/GlobalStyles';

const Layout = ({ children }) => (
  <ThemeProvider theme={theme}>
    <>
      <SEO />
      <GlobalStyles />
      <Container>
        <Wrapper>
          <Content>{children}</Content>
          <Footer>© Buceo Islas Cíes</Footer>
        </Wrapper>
      </Container>
    </>
  </ThemeProvider>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

const Container = styled.div`
  flex-direction: column;
  position: relative;
  padding-bottom: 0px;
  min-height: 100vh;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  display: flex;
`;

const Wrapper = styled.div`
  flex: 1 1 0%;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px 12px;
  height: 100%;
  width: 100%;
  display: flex;
`;

const Content = styled.div`
  margin: 0px auto;
  height: 100%;
  width: 100%;
  max-width: 680px;
`;

const Footer = styled.div`
  padding-top: 60px;
  text-align: center;
`;

export default Layout;
