import React from 'react';

import ButtonLink from '@common/ButtonLink';

const snakeCase = (string) => {
  return string
    .replace(/\W+/g, ' ')
    .split(/ |\B(?=[A-Z])/)
    .map((word) => word.toLowerCase())
    .join('_');
};

const DATA = [
  {
    title: 'Web',
    href: 'https://buceoislascies.es',
  },
  {
    title: 'Bautizo de buceo',
    href: 'https://buceoislascies.es/producto/bautizo-de-buceo-islas-cies-vigo/',
  },
  // {
  //   title: 'Viaje Mar Rojo Octubre 2021',
  //   href:
  //     'https://buceoislascies.es/viajes/vida-a-bordo-mar-rojo-octubre-2021/',
  // },
  {
    title: 'WhatsApp',
    href: 'https://wa.me/34648543730',
  },
  {
    title: 'Telegram',
    href: 'https://t.me/buceoislascies',
  },
  {
    title: 'Instagram',
    href: 'https://instagram.com/buceoislascies',
  },
  {
    title: 'Facebook',
    href: 'http://facebook.com/buceoislascies',
  },
];

const Links = () => {
  return DATA.map((link) => (
    <ButtonLink
      key={snakeCase(link.title)}
      href={link.href}
      title={link.title}
    />
  ));
};

export default Links;
