import React from 'react';
import styled from 'styled-components';

const ExternalLink = ({ href, children, ...other }) => (
  <A href={href} {...other} rel="noreferrer noopener" target="_blank">
    {children}
  </A>
);

const A = styled.a`
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: auto;
  position: relative;
  padding: 16px 20px;
  margin: 0px;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  width: 100%;
  cursor: pointer;
  appearance: none;
  text-align: center;
  box-sizing: border-box;
  text-decoration: none;
  overflow-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
  white-space: normal;
  background: none;
  color: inherit;
  transition: box-shadow 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0s,
    border-color 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0s,
    transform 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0s,
    background-color 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0s;

  p {
    padding: 0px;
    margin: 0px;
    line-height: 1.5;
    width: 100%;
  }
`;

export default ExternalLink;
